import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Menu = ({ className, menu }) => (
  <div className={`${className} text-14 w-1/2 lg:w-1/3`}>
    <div className="inline mx-auto">
      <p className="text-14 font-bold mb-6 hidden md:block">{menu.menuTitle}</p>
      <ul>
        {menu.menu.items.map((menuItem, index) => {
          let link = menuItem.url
          if (link) {
            if (!link.includes('?theme') && !menuItem.url.endsWith('/')) {
              link = `${menuItem.url}/`
            }
          }

          return (
            <li key={index} className="mb-4 md:mb-2">
              <Link to={link}>{menuItem.title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  </div>
)

Menu.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.object,
}

Menu.defaultProps = {
  className: '',
  menu: {},
}

export default Menu
